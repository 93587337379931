import axios from "@/lib/axios"; // Interceptors
import { getInstance } from "@/auth";

async function getAppCustomEnv() {
  return {
    SIMPATRA_APP_LAB_RESULT_URL: process.env.VUE_APP_LAB_RESULT_URL,
    SIMPATRA_SERVICE_URL: process.env.VUE_APP_API_ROOT,
    VUE_APP_SELF_ROOT: process.env.VUE_APP_SELF_ROOT,
  };
}

const authenticatedConfigForAPIRequest = addedHeaders => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  if (localStorage.access_token) {
    config.headers.Authorization = `Bearer ${localStorage.access_token}`;
  }

  if (addedHeaders && typeof addedHeaders === "object") {
    config.headers = { ...config.headers, ...addedHeaders };
  }

  return config;
};

const auth0Config = additionalConfig => {
  const config = {
    headers: {
      Accept: "application/json",
      "biote-auth": additionalConfig.accessToken,
    },
  };

  return config;
};

const validateResponse = async response => {
  if (response.status === 401) {
    localStorage.removeItem("exp");
    localStorage.removeItem("hydraState");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    const authService = await getInstance();
    const { logout } = await authService.$auth;
    await logout();
    window.reload();
    return false;
  }
  return true;
};

export default class BaseProxy {
  async getSimpatraLabResultUrl() {
    const appCustomEnv = await getAppCustomEnv();
    return `${appCustomEnv.SIMPATRA_APP_LAB_RESULT_URL}`;
  }

  async getSimpatraServiceUrl() {
    const appCustomEnv = await getAppCustomEnv();
    return `${appCustomEnv.SIMPATRA_SERVICE_URL}`;
  }

  async getSimpatraLabResultData(url) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: "Basic bnRSSWZpUkxpQU5rWUxPVG9NUHM6KkA4VEdAWURKbCRpc0kjdCFyVTBpaVZ3UA==",
      },
    };
    return await axios.get(url, config);
  }

  async getSimpatraServiceData(url, queryParams, axiosConfig = {}) {
    const config = authenticatedConfigForAPIRequest();
    const appCustomEnv = await getAppCustomEnv();
    config.params = queryParams;
    config.origin = appCustomEnv.VUE_APP_SELF_ROOT;
    if (axiosConfig && axiosConfig.headers) {
      config.headers = axiosConfig.headers;
    }

    const response = await axios.get(url, config);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async postSimpatraServiceData(url, payload, axiosConfig = {}) {
    const config = authenticatedConfigForAPIRequest(axiosConfig.addedHeaders);
    const propToDelete = "addedHeaders"; // Replace 'propertyName' with the actual property name you want to delete
    if (axiosConfig[propToDelete]) {
      delete axiosConfig[propToDelete];
    }
    const mergedConfig = Object.assign({}, config, axiosConfig);
    const response = await axios.post(url, payload, mergedConfig);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async patchSimpatraServiceData(url, payload) {
    const config = authenticatedConfigForAPIRequest();
    const response = await axios.patch(url, payload, config);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async deleteSimpatraServiceData(url, queryParams) {
    const config = authenticatedConfigForAPIRequest();
    config.params = queryParams;
    const response = await axios.delete(url, config);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async putSimpatraServiceData(url, payload) {
    const config = authenticatedConfigForAPIRequest();
    config.headers["content-type"] = "application/json";
    const response = await axios.put(url, payload, config);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async getAuth0ServiceProxy(url, axiosConfig) {
    const config = auth0Config(axiosConfig);
    const response = await axios.get(url, config);
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }

  async postAuth0ServiceProxy(url, payload, axiosConfig) {
    const config = auth0Config(axiosConfig);
    const response = await axios.post(url, payload, { ...config });
    const isValid = validateResponse(response);
    if (isValid) {
      return response;
    }
  }
}
