import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";
import { get } from "lodash";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class PutSimpatraServiceProxy {
  async execute(query = "", payload, notificationParams) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      const response = await baseProxy.putSimpatraServiceData(endpointUrl, payload);
      const notificationHeader = get(notificationParams, "successNotificationHeader", "Successfully saved!");
      const notificationMsg = get(notificationParams, "successNotificationMsg", "") || "";
      if (notificationParams && notificationParams.displaySuccessMessage) {
        notification(NOTIFICATION_CONSTANTS.SUCCESS, notificationHeader, notificationMsg);
      }
      return response;
    } catch (error) {
      const errorMsgHeader = error.response && error.response.data ? error.response.data.error || "ERROR!" : error.response.error ? error.response.error : "Event failed due to error!";
      const unauthorizedMsg = ["invalid_token", "unauthorized"];
      const errorHeader = unauthorizedMsg.includes(errorMsgHeader) ? "Unauthorized Access!" : errorMsgHeader;
      const errorMessage = notificationParams?.errorNotificationMsg ?? error.response?.data?.message ?? error.response?.data?.error_description ?? error.response?.error_description ?? error.response?.message ?? "";
      notification(NOTIFICATION_CONSTANTS.ERROR, errorHeader, errorMessage);
      if (notificationParams && notificationParams.returnError) {
        return error && error.response;
      }
    }
  }
}
