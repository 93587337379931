import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchDirectoryProfiles = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "/directory/v1/provider";
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setDirectoryProfiles", data);
    return data;
  }
};

const updateDirectoryProfile = async ({ commit }, payload) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `directory/v1/provider/id/${payload.id}`;
  const response = await proxy.execute(query, payload);
  if (response) {
    const { data } = response;
    return data;
  }
};

// updateProfile: async ({ commit, getters }) => {
//   const profile = getters.getCurrentProfile;
//   profile.locations.forEach((l) => {
//     l.geoPoint = l.geoPoint.map((p) => {
//       return parseFloat(p);
//     });
//   });
//   const response = await axios.put(
//     `directory/v1/provider/id/${getters.getCurrentProfile.id}`,
//     profile,
//   );
//   commit('setCurrentProfile', response.data);
// },

export default {
  fetchDirectoryProfiles,
  updateDirectoryProfile,
};
