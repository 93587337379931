import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";
import { get } from "lodash";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class PostSimpatraServiceProxy {
  async execute(query = "", payload = {}, notificationParams, axiosConfig = {}) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      const response = await baseProxy.postSimpatraServiceData(endpointUrl, payload, axiosConfig);
      const notificationHeader = get(notificationParams, "successNotificationHeader", "Successfully saved!");
      if (notificationParams && notificationParams.displaySuccessMessage) {
        notification(NOTIFICATION_CONSTANTS.SUCCESS, notificationHeader, get(notificationParams, "successNotificationMsg", ""));
      }
      return response;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};

      const errorMsgHeader = errorData.error || errorResponse.error || "Event failed due to error!";
      const unauthorizedMsg = ["invalid_token", "unauthorized"];
      const errorHeader = unauthorizedMsg.includes(errorMsgHeader)
        ? "Unauthorized Access!"
        : notificationParams?.errorNotificationHeader || errorMsgHeader;

      const errorMessage =
        notificationParams?.errorNotificationMsg ?? errorData.message ?? errorData.error_description ?? errorResponse.error_description ?? errorResponse.message ?? "";

      const notificationHeader = errorHeader;
      const notificationMessage = errorMessage;

      notification(NOTIFICATION_CONSTANTS.ERROR, notificationHeader, notificationMessage);

      if (notificationParams?.returnError) {
        return error && error.response;
      }
    }
  }
}
