// import { get } from "lodash";

const setVendorUsers = (state, payload) => {
  // const data = get(payload, "content", []);
  // state.orgUsers.paginationDetails.currentPage = get(payload, "number", 0);
  // state.orgUsers.paginationDetails.totalPages = get(payload, "totalPages", 0);
  // state.orgUsers.paginationDetails.pageSize = get(payload, "size", 0);
  // state.orgUsers.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.vendorUsers.data = payload;
};

const updateVendorUsersList = (state, payload) => {
  const userIndex = state.vendorUsers.data.findIndex(user => user.id === payload.data.id);
  if (payload.type === "DELETE") {
    state.vendorUsers.data.splice(userIndex, 1);
  } else if (payload.type === "UPDATE") {
    state.vendorUsers.data.splice(userIndex, 1, payload.data);
  } else {
    state.vendorUsers.data.push(payload.data);
  }
};

const setLaboratories = (state, payload) => {
  state.laboratories = payload;
};

const setVendorShipping = (state, payload) => {
  state.vendorShipping = payload;
};

const updateVendorShippingList = (state, payload) => {
  // I used shippingClass because id is always changing after update
  const shippingIndex = state.vendorShipping.findIndex(
    shipping =>
      shipping.shippingClass.replace(/\s+/g, "").toLowerCase() ===
      payload.data.shippingClass.replace(/\s+/g, "").toLowerCase()
  );
  state.vendorShipping.splice(shippingIndex, 1, payload.data);
};

export default {
  setVendorUsers,
  updateVendorUsersList,
  setLaboratories,
  setVendorShipping,
  updateVendorShippingList
};
