import { cloneDeep, isEmpty } from "lodash";
import store from "@/store";
import userMe from "@/services/user-me";

const navigateBasedOnRoles = async ({ decodedToken }) => {
  const { isVendorUser, isSimpatraAdmin } = userMe.getMyRoles();
  const { id } = decodedToken.sim.details; // orgId or vendorId
  const selectedVendor = localStorage.getItem("selected_vendor");
  const hasSelecetedVendor = !["", null, undefined].includes(selectedVendor);
  const callbackRoute = cloneDeep(localStorage.getItem("callbackRoute"));
  const hasCallback = !["", null, undefined].includes(callbackRoute);
  if (hasCallback) {
    this.$router.push({ path: callbackRoute });
    localStorage.removeItem("callbackRoute");
  } else if (isVendorUser || (hasSelecetedVendor && isSimpatraAdmin)) {
    this.$router.push({ path: `/dashboard/vendor/${selectedVendor}` });
  } else {
    const orgId = id;
    const currentSelectedLocation = await fetchUserLocations(orgId);
    if (currentSelectedLocation) {
      this.$router.push(`/dashboard/${currentSelectedLocation}/patient-chart`);
    }
  }
};

const fetchUserLocations = async currentOrgId => {
  const { isSimpatraAdmin } = userMe.getMyRoles();
  let orgId = localStorage.getItem("orgId") || currentOrgId;
  const hasSelectedOrg = !["", null, undefined].includes(orgId);
  // Reset orgId if current user is not a super admin
  orgId = hasSelectedOrg && isSimpatraAdmin ? orgId : currentOrgId;
  localStorage.setItem("orgId", orgId);

  // Fetch current logged in user accessible locations
  const queryParams = isSimpatraAdmin ? { orgId } : {};
  const userAccessibleLocations = await store.dispatch("usersModule/fetchLoggedUserAccessibleLocations", queryParams);
  const currentUserAvailableLocations = userAccessibleLocations?.locations || [];
  const userActiveLocations = currentUserAvailableLocations.filter(item => item.isActive === true);
  store.commit("locationsModule/setCurrentUserAvailableLocation", currentUserAvailableLocations);

  let locationId = localStorage.getItem("currentLocation");
  const emptySelectedLocation = ["", null, undefined].includes(locationId);
  // Check current selected location if existed in available locations
  const isLocationExist = currentUserAvailableLocations.find(item => item.id === locationId);
  locationId = isEmpty(isLocationExist) || emptySelectedLocation ? userActiveLocations[0]?.id : locationId;
  localStorage.setItem("currentLocation", locationId);
  return locationId;
};

export default {
  fetchUserLocations,
  navigateBasedOnRoles,
};
