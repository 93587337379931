export default {
  state: {
    detail: {},
  },

  getters: {
    getCurrentPatientDetail: state => {
      return state.detail;
    },
  },

  mutations: {
    setCurrentPatientDetail(state, payload) {
      state.detail = payload;
    },
  },

  actions: {
    commitCurrentPatientDetail: ({ commit }, payload) => {
      commit("setCurrentPatientDetail", payload);
    },
  },
};
