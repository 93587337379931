import BaseProxy from "../../base-proxy";
import notification from "@/utils/notifications";
import { get } from "lodash";
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class GetSimpatraServiceProxy {
  async execute(query = "", queryParams = {}, additionalConfig, axiosConfig) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;

    try {
      this.processQueryParams(queryParams);
      return await baseProxy.getSimpatraServiceData(endpointUrl, queryParams, axiosConfig);
    } catch (err) { // Changed 'error' to 'err' for clarity
      this.handleError(err, additionalConfig); // Pass 'err' to handleError
      return err; // Return the error for further handling if needed
    }
  }

  processQueryParams(queryParams) {
    if (!queryParams) return;
    const query = get(queryParams, "q", "").trim();
    if (query) {
      queryParams.q = /[^a-zA-Z0-9+ ]/.test(query) ? encodeURIComponent(query) : query;
    }
  }

  handleError(err, additionalConfig) { // Changed 'error' to 'err'
    const errorMsgHeader = this.getErrorMessageHeader(err); // Use 'err' here
    const errorMsg = this.getErrorMessage(err, additionalConfig); // Use 'err' here

    if (additionalConfig && additionalConfig.notificationParams) {
      this.notifyError(errorMsgHeader, errorMsg, additionalConfig.notificationParams);
    } else {
      notification(NOTIFICATION_CONSTANTS.ERROR, errorMsgHeader, err.response?.data?.message || ""); // Use 'err' here
    }
  }

  getErrorMessageHeader(err) { // Changed 'error' to 'err'
    return err.response?.data?.error || err.response?.error || "Event failed due to error!"; // Use 'err' here
  }

  getErrorMessage(err, additionalConfig) { // Changed 'error' to 'err'
    if (additionalConfig?.notificationParams) {
      return additionalConfig.notificationParams.errorNotificationMsg || err.response?.data?.message || null; // Use 'err' here
    }
    return null;
  }

  notifyError(errorMsgHeader, errorMsg, notificationParams) {
    if (!notificationParams.hideErrorNotif) {
      notification(NOTIFICATION_CONSTANTS.ERROR, errorMsgHeader, errorMsg);
    }
    if (notificationParams.returnError) {
      return notificationParams.error.response; // Ensure this is correct
    }
  }
}
