import { isEmpty } from "lodash";
import axios from "../lib/axios";

export default {
  namespaced: true,

  state: {
    practiceProviders: [],
    details: {
      profiles: [],
      currentProfile: {
        id: "",
        orgId: "",
        userId: "",
        prefix: "",
        firstName: "",
        lastName: "",
        credentials: "",
        imgUrl: "",
        slug: "",
        phone: "",
        description: "",
        specialties: [
          {
            specialty: "",
          },
        ],
        annotations: [
          {
            title: "",
            body: "",
          },
        ],
        locations: [
          {
            name: "",
            address1: "",
            address2: "",
            city: "",
            region: "",
            postalCode: "",
            geoPoint: [],
          },
        ],
      },
    },
    patients: [],
    testPatients: [],
    livePatients: [],
    allPatientPage: 1,
    allPatientPageLength: 20,
    testPatientPage: 1,
    testPatientPageLength: 20,
    livePatientPage: 1,
    livePatientPageLength: 20,
  },

  getters: {
    getPracticeProviders: state => {
      return state.practiceProviders;
    },
    getAllProfiles: state => {
      return state.details.profiles;
    },
    getCurrentProfile: state => {
      return state.details.currentProfile;
    },
    getAllPatients: state => {
      return state.patients;
    },
    getLivePatients: state => {
      return state.livePatients;
    },
    getTestPatients: state => {
      return state.testPatients;
    },

    getNumberOfAllPages(state, getters) {
      const pages = Math.ceil(
        getters.getAllPatients.length / getters.getAllPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getAllPageLength(state) {
      return state.allPatientPageLength;
    },
    getAllPage(state) {
      return state.allPatientPage;
    },
    getAllPageResults(state, getters) {
      const page = getters.getAllPage;
      const results = getters.getAllPatients;
      const pageLength = getters.getAllPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },

    getNumberOfLivePages(state, getters) {
      const pages = Math.ceil(
        getters.getLivePatients.length / getters.getLivePageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getLivePageLength(state) {
      return state.livePatientPageLength;
    },
    getLivePage(state) {
      return state.livePatientPage;
    },
    getLivePageResults(state, getters) {
      const page = getters.getLivePage;
      const results = getters.getLivePatients;
      const pageLength = getters.getLivePageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },

    getNumberOfTestPages(state, getters) {
      const pages = Math.ceil(
        getters.getTestPatients.length / getters.getTestPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getTestPageLength(state) {
      return state.testPatientPageLength;
    },
    getTestPage(state) {
      return state.testPatientPage;
    },
    getTestPageResults(state, getters) {
      const page = getters.getTestPage;
      const results = getters.getTestPatients;
      const pageLength = getters.getTestPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setProviders(state, payload) {
      state.practiceProviders = payload;
    },
    setAllPage(state, payload) {
      state.allPatientPage = payload;
    },
    setLivePage(state, payload) {
      state.livePatientPage = payload;
    },
    setTestPage(state, payload) {
      state.testPatientPage = payload;
    },
    setProfiles(state, payload) {
      state.details.profiles = payload;
    },
    setLivePatients(state, payload) {
      state.livePatients = payload;
    },
    setTestPatients(state, payload) {
      state.testPatients = payload;
    },
    setAllPatients(state, payload) {
      state.patients = payload;
    },
    setCurrentProfile(state, payload) {
      payload.annotations = isEmpty(payload.annotations) ? [] : payload.annotations;
      payload.specialties = isEmpty(payload.specialties) ? [] : payload.specialties;
      payload.locations = isEmpty(payload.locations) ? [] : payload.locations;
      state.details.currentProfile = payload;
    },
    setCurrentProfileIdUpdates(state, payload) {
      const { orgId, userId } = payload;
      state.details.currentProfile.orgId = orgId;
      state.details.currentProfile.userId = userId;
    },
    setNewSpecialty(state) {
      state.details.currentProfile.specialties.push({
        specialty: "",
      });
    },
    setNewAnnotation(state) {
      state.details.currentProfile.annotations.push({
        title: "",
        body: "",
      });
    },
    setNewLocation(state) {
      state.details.currentProfile.locations.push({
        name: "",
        address1: "",
        address2: "",
        city: "",
        region: "",
        postalCode: "",
        geoPoint: [],
      });
    },
  },

  actions: {
    fetchProviderByPractice: async ({ commit }, payload) => {
      const { orgId } = payload;
      const response = await axios.get(
        `/accounts/v1/org/${orgId}/user`,
      );
      commit("setProviders", response.data);
    },
    sendNotificationToPractice: async ({ commit }, payload) => {
      const body = {
        orgId: payload.orgId,
      };
      const { sessionId } = payload;
      const { orgId } = payload;
      const response = await axios.put(
        `/accounts/v1/vendor/typeform/${sessionId}/org/${orgId}`,
        body,
      );
      commit("setCurrentProfile", response.data);
    },
    sendNotificationToPracticeProvider: async ({ commit }, payload) => {
      const body = {
        providerId: payload.providerId,
      };
      const { orgId } = payload;
      const { sessionId } = payload;
      const { providerId } = payload;
      const response = await axios.put(
        `/accounts/v1/vendor/typeform/${sessionId}/org/${orgId}/provider/${providerId}`,
        body,
      );
      commit("setCurrentProfile", response.data);
    },
    nextLivePage({ getters, commit }) {
      commit("setLivePage", getters.getLivePage + 1);
    },
    prevLivePage({ getters, commit }) {
      commit("setLivePage", getters.getLivePage - 1);
    },
    commitLivePage({ commit }, payload) {
      commit("setLivePage", payload);
    },

    nextTestPage({ getters, commit }) {
      commit("setTestPage", getters.getTestPage + 1);
    },
    prevTestPage({ getters, commit }) {
      commit("setTestPage", getters.getTestPage - 1);
    },
    commitTestPage({ commit }, payload) {
      commit("setTestPage", payload);
    },

    nextAllPage({ getters, commit }) {
      commit("setAllPage", getters.getAllPage + 1);
    },
    prevAllPage({ getters, commit }) {
      commit("setAllPage", getters.getAllPage - 1);
    },
    commitAllPage({ commit }, payload) {
      commit("setAllPage", payload);
    },

    fetchAllPatients: async ({ commit }) => {
      const response = await axios.get(
        "/accounts/v1/org/typeform/responses",
      );
      const { live } = response.data;
      const { test } = response.data;
      const all = [...live, ...test];
      commit("setAllPatients", all);
      commit("setLivePatients", response.data.live);
      commit("setTestPatients", response.data.test);
    },
    fetchAllProfiles: async ({ commit }) => {
      const response = await axios.get(
        "/directory/v1/provider",
      );
      commit("setProfiles", response.data);
    },
    fetchProfileByUserId: async ({ commit, getters }, userId) => {
      const response = await axios.get(
        `directory/v1/provider/userId/${userId}?userId=${userId}`,
      );
      if (response.data.length > 0) {
        commit("setCurrentProfile", response.data[0]);
      } else {
        commit("setCurrentProfile", getters.getCurrentProfile);
      }
    },
    fetchOneProfile: async ({ commit }, profileId) => {
      const response = await axios.get(
        `/directory/v1/provider/id/${profileId}`,
      );
      await commit("setCurrentProfile", response.data);
    },
    createProfile: async ({ commit, getters }) => {
      const profile = getters.getCurrentProfile;
      if (profile.orgId === "" || profile.userId === "") {
        profile.orgId = "00000000-0000-0000-0000-000000000000";
        profile.userId = "00000000-0000-0000-0000-000000000000";
      }
      profile.locations.forEach(l => {
        l.geoPoint = l.geoPoint.map(p => {
          return parseFloat(p);
        });
      });
      const response = await axios.post(
        "directory/v1/provider/",
        profile,
      );
      commit("setCurrentProfile", response.data);
    },
    updateProfile: async ({ commit, getters }) => {
      const profile = getters.getCurrentProfile;
      profile.locations.forEach(l => {
        l.geoPoint = l.geoPoint.map(p => {
          return parseFloat(p);
        });
      });
      const response = await axios.put(
        `directory/v1/provider/id/${getters.getCurrentProfile.id}`,
        profile,
      );
      commit("setCurrentProfile", response.data);
    },
    commitCurrentProfile: async ({ commit }, profile) => {
      commit("setCurrentProfile", profile);
    },
    commitCurrentProfileIdUpdates: async ({ commit }, { orgId, userId }) => {
      commit("setCurrentProfileIdUpdates", { orgId, userId });
    },
    commitNewSpecialty: async ({ commit }) => {
      commit("setNewSpecialty");
    },
    commitNewAnnotation: async ({ commit }) => {
      commit("setNewAnnotation");
    },
    commitNewLocation: async ({ commit }) => {
      commit("setNewLocation");
    },
    clearCurrentProfile: async ({ commit }) => {
      commit("setCurrentProfile", {
        id: "",
        orgId: "",
        userId: "",
        prefix: "",
        firstName: "",
        lastName: "",
        credentials: "",
        imgUrl: "",
        slug: "",
        phone: "",
        description: "",
        specialties: [
          {
            specialty: "",
          },
        ],
        annotations: [
          {
            title: "",
            body: "",
          },
        ],
        locations: [
          {
            name: "",
            address1: "",
            address2: "",
            city: "",
            region: "",
            postalCode: "",
            geoPoint: [],
          },
        ],
      });
    },
  },
};
