import { isEmpty } from "lodash";
import userMe from "@/services/user-me";

const getOrgDetails = state => {
  return state.orgDetails;
};

const getOrgLocations = state => {
  return state.orgLocations;
};

const getOrgUsers = state => {
  return state.orgUsers;
};

const getOrgDosingDetails = state => {
  return state.orgDosingDetails;
};

const getOrgDosingUsers = state => {
  return state.orgDosingUsers;
};

const getOrgList = state => {
  return state.orgList;
};

const getDeaLicenses = state => {
  return state.deaLicenses;
};

const getMedicalLicenses = state => {
  return state.medicalLicenses;
};

const getNPI = state => {
  return state.npi;
};

const getOrgUserLookup = state => {
  return state.orgUserLookup;
};

export default {
  getOrgDetails,
  getOrgLocations,
  getOrgUsers,
  getOrgDosingUsers,
  getOrgDosingDetails,
  getOrgList,
  getDeaLicenses,
  getMedicalLicenses,
  getNPI,
  getOrgUserLookup,
};
