import axios from "@/lib/axios";
import notification from "@/utils/notifications";

const fetchCurrentUserConsults = async ({ commit }, userId) => {
  try {
    const response = await axios.get(
      `/consult/v1/provider/${userId}/consult`,
    );
    commit("setCurrentUserConsults", response.data);
  } catch (error) {
    notification("warning", error.response.data.message);
  }
}

export default {
  fetchCurrentUserConsults
}
